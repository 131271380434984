<template>
  <div class="containerx ma-6">
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row flex-grow-1">
          <div class=" d-flex flex-column flex-grow-1">
            <h2 class=" ft font-weight-bold font-size-lg blue-grey--text">
              Stock Levels Overview
            </h2>
            <p class=" ft font-weight-medium">
              General overview of monthly stock levels
            </p>
          </div>
          <div class="d-flex flex-column">
            <v-autocomplete
              :items="branches"
              item-text="name"
              item-value="id"
              dense
              class=" ft font-weight-medium pr-10 mt-3 font-size-sm"
              v-model="branchId"
              outlined
              style="min-width: 450px;"
              label="Choose a branch"
            >
              <template #item="{item}">
                <span class=" ft font-weight-medium font-size-sm"
                  >{{ item.name }}
                </span>
              </template>
            </v-autocomplete>
          </div>
        </div>
      </v-col>

      <v-col cols="12">
        <v-tabs v-model="tab" class="mt-n5">
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1" class=" ft">
            Stocks Level Overview
          </v-tab>

          <v-tab href="#tab-2" class=" ft">
            Stocks Variation (Fuels)
          </v-tab>
          <v-tab href="#tab-3" class=" ft">
            Stocks Variation (Lubes)
          </v-tab>

          <v-spacer />
          <template v-if="tab !== 'tab-1'">
            <v-chip-group v-model="selectedDuration">
              <v-chip
                v-for="(duration, index) in durations"
                :key="index"
                label
                small
                color="white"
                :mandatory="dates.length === 0"
                active-class="primary"
                class=" box-shadow-light ft text-uppercase font-weight-medium"
              >
                {{ duration.text }}
              </v-chip>
            </v-chip-group>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              class="ft font-weight-medium"
              min-width="auto"
              :nudge-left="120"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-model="dates"
                  v-bind="attrs"
                  v-on="on"
                  label
                  :close="dates.length === 2"
                  @click:close="dates = []"
                  small
                  :color="dates.length > 0 ? 'primary' : 'white'"
                  class=" box-shadow-light mt-2 ft text-uppercase font-weight-medium"
                >
                  <i class="material-icons-outlined font-size-md mr-2">event</i>
                  {{ dates.length > 0 ? dateRangeText : "Choose date range" }}
                </v-chip>
              </template>
              <v-date-picker
                class="ft font-weight-medium font-size-sm"
                v-model="dates"
                range
              >
                <v-spacer></v-spacer>
                <v-btn
                  small
                  class=" ft font-weight-medium"
                  text
                  color="primary"
                  @click="(menu = false), (date = [])"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  @click="filteredByDates"
                  class="ft font-weight-medium"
                  color="primary"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </template>
        </v-tabs>
        <v-progress-circular indeterminate v-if="pageLoading" />
        <v-tabs-items v-else v-model="tab">
          <v-tab-item value="tab-1">
            <v-row>
              <v-col cols="12">
                <StockLevelsOverview :branchId="branchId" />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <StockSoldOverviewFuels
              :currency="currency"
              :period="durations[selectedDuration]"
              :years="years"
              :range="dates"
              :branchId="branchId"
            />
          </v-tab-item>
          <v-tab-item value="tab-3">
            <StockSoldOverviewLubes
              :currency="currency"
              :period="durations[selectedDuration]"
              :years="years"
              :range="dates"
              :branchId="branchId"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "StocksIndex",
  computed: {
    ...mapGetters({
      currency: "settings/getSystemCurrency",
      years: "reports/getAllRecordedYears",
      branches: "branches/getAllBranches",
      pageLoading: "getIsPageLoading",
      listLoading: "getIsListLoading",
      subLoading: "getIsSubLoading"
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    }
  },
  components: {
    StockLevelsOverview: () => import(`./StockLevelsOverview`),
    StockSoldOverviewFuels: () => import(`./StockSoldFuelsOverview`),
    StockSoldOverviewLubes: () => import(`./StockSoldLubesOverview`)
  },
  data() {
    return {
      menu: false,
      dates: [],
      items: ["Stock Level Overview", "Deliveries"],
      durations: [
        { text: "1 week", number: 1, type: "week" },
        { text: "2 weeks", number: 2, type: "weeks" },
        { text: "3 weeks", number: 3, type: "weeks" },
        { text: "1 Month", number: 1, type: "month" }
      ],
      selectedDuration: 0,
      tab: null,
      branchId: null
    };
  },
  watch: {
    dates(dates) {
      if (dates.length >= 1) {
        this.selectedDuration = null;
      } else this.selectedDuration = 0;
    },
    selectedDuration() {
      if (this.dates.length === 2) {
        this.dates = [];
      }
    },
    branches(branchList) {
      this.branchId = branchList.length > 0 ? branchList[0]?.id : null;
      this.query = `?branchId=${this.branchId}`;
      this.$store.dispatch("reports/recordedYears", this.query);
    }
  },
  methods: {
    filteredByDates() {
      this.$refs.menu.save(this.dates);
    }
  },
  created() {
    this.$store.dispatch("branches/list", { isPaginated: false });
  }
};
</script>
